import { lazy } from "react";
const Login = lazy(() => import("../pages/login/login"));
const About = lazy(() => import("../pages/about/about"));
const routeObjects = [
  {
    path: "/",
    component: Login,
  },
  // {
  //   path: "/about",
  //   component: About,
  // },
];

export default routeObjects;
