import { Fragment, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import NotFound from "../pages/notfound/notFound";

import PrivateRouteValidator from "./components/privateRouteValidator";
import privateRoutes from "./privateRoutes";

import PublicRouteValidator from "./components/publicRouteValidator";
import publicRoutes from "./publicRoutes";

import ProtectedRouteValidator from "./components/protectedRouteValidator";
import protectedRoutes from "./protectedRoutes";
import Loader from "../components/common/loader/loader.spinner";
import RenderChild from "./components/renderChild";

function AppRoutes() {
  return (
    <Fragment>
      <Suspense
        fallback={<Loader size={32} role="status" className="spinner-border" />}
      >
        <Routes>
          {privateRoutes.map((route, index) => (
            <Route
              path={route.path}
              key={index}
              exact
              strict
              element={
                <PrivateRouteValidator>
                  <RenderChild>
                    <route.component />
                  </RenderChild>
                </PrivateRouteValidator>
              }
            />
          ))}
          {publicRoutes.map((route, index) => (
            <Route
              path={route.path}
              key={index}
              exact
              strict
              element={
                <PublicRouteValidator>
                  <route.component />
                </PublicRouteValidator>
              }
            />
          ))}

          {protectedRoutes.map((route, index) => (
            <Route
              path={route.path}
              key={index}
              exact
              strict
              element={
                <ProtectedRouteValidator>
                  <RenderChild>
                    <route.component />
                  </RenderChild>
                </ProtectedRouteValidator>
              }
            />
          ))}

          <Route path="*" exact strict element={<NotFound />} />
          
        </Routes>
      </Suspense>
    </Fragment>
  );
}

export default AppRoutes;
