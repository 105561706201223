import React from "react";
import UserList from "../../features/users/components/usersList";
const ListAllUser = () => {
  return (
    <div>
       <UserList />
    </div>
  );
};

export default ListAllUser;
