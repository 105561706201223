import { lazy } from "react";

const Home = lazy(() => import("../pages/homepage/homepage"));
const UserList  = lazy(()=> import("../pages/users/userlist"));
const UnProcessedGiftCardsList  = lazy(()=> import("../pages/users/unProcessedGiftCardsList"));

const routeObjects = [
  {
    path: "/dashboard",
    component: Home,
  },
  {
    path: "/userList",
    component: UserList,
  },
  {
    path: "/unprocess_giftcards",
    component: UnProcessedGiftCardsList,
  },

];

export default routeObjects;
