import React from "react";
import GenNav from "../../features/genNav/components/genNav";
const nav = () => {
  return (
    <div>
      <GenNav />
    </div>
  );
};

export default nav;