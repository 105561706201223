
import PropTypes from "prop-types";
import {  IsLogin } from "../../utils/utilAuth";
import { Navigate } from "react-router-dom";

const ProtectedRouteValidator = ({ children }) => {
  const isLogin = IsLogin();
  if (!isLogin) {
    return <Navigate to="/" replace />;
  } else {
    return children;
  }
};

ProtectedRouteValidator.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ProtectedRouteValidator;
