import { Fragment } from "react";
import SideNave from "../../features/genNav/components/sideNav";
import { useSelector } from "react-redux";
import Navbar from "../../components/menu/nav";
import { Box } from "@mui/material";
export default ({ children }) => {
  const currentUser = useSelector((state) =>
    state.auth ? state.auth.currentUser : null
  );
  const loggingStatus = useSelector((state) =>
    state.auth ? state.auth.loggedIn : false
  );
  return (
    <Fragment>
      <Navbar currentUser={currentUser} loggingStatus={loggingStatus} />
      <Box height={40} />

      <Box sx={{ display: "flex" }}>
        <SideNave />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <Box height={40} />

          {children}
        </Box>
      </Box>
    </Fragment>
  );
};
