import * as React from "react";
import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { useSelector, useDispatch } from "react-redux";
import { updateOpen } from "../slice/navSlice";
import { styled } from "@mui/material/styles";
import LogoImage from "../../../assets/images/piemob_logo.png";
import UserList from "../../../pages/users/userlist";
import "./navStyle.css";
const AppBar = styled(
  MuiAppBar,
  {}
)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
}));

// const pages = ["Products", "Pricing", "Blog"];
const settings = [
  "Profile",
  "Account",
  "Dashboard",
  "Logout",
  "Change Password",
];

function GenNav() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const dopen = useSelector((state) => state.app.dopen);
  const dispatch = useDispatch();
  const handleUpdateOpen = (open) => {
    dispatch(updateOpen(open));
  };
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <>
      <AppBar
        position="fixed"
        elevation={0}
        sx={{ background: "#ffffff", color: "#2f2f2f" }}
      >
        <Box className="navContainer header-shadow">
          <Toolbar disableGutters className="toolbar">
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={() => dispatch(updateOpen(!dopen))}
            >
              <MenuIcon />
            </IconButton>
            <img
              src={LogoImage}
              alt="Logo"
              style={{ height: "67"}}
            />
            
            <Box sx={{ flexGrow: 0 }}>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem key={setting} onClick={handleCloseUserMenu}>
                    <Typography textAlign="center">{setting}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </Box>
      </AppBar>
    </>
  );
}
export default GenNav;
