import PageNotFound from "./components/pageNotFound";
import PropTypes from "prop-types";

const NotFound = () => {
  return <PageNotFound />;
};

NotFound.propTypes = {};

export default NotFound;
