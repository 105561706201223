import React, { Fragment } from "react";
import AppRoutes from "./routes";
import { useSelector } from "react-redux";
import "./app.css";
function App() {
  const currentUser = useSelector((state) =>
    state.auth ? state.auth.currentUser : null
  );
  const loggingStatus = useSelector((state) =>
    state.auth ? state.auth.loggedIn : false
  );
  return (
    <Fragment>
      <AppRoutes
        currentUser={currentUser}
        loggingStatus={loggingStatus}
        role={currentUser?.role}
      />
    </Fragment>
  );
}

export default App;
