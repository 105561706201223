import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axiosInstance from "../../../services/axios";
import { useQuery } from "react-query";
import "./userStyles.css";
import SearchIcon from "@mui/icons-material/Search";
import {
  Table,
  TableBody,
  TableCell,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
  FormControl,
  TableContainer,
  TableHead,
  InputBase,
  TableRow,
  Paper,
  TablePagination,
  TextField,
  Button,
  Box,
} from "@mui/material";
import IOSSwitch from "../../../components/form-controls/switch/Switch";
import { createNotification } from "../../../helpers/createNotifications";

const UserList = () => {
  const [userListData, setUserListData] = useState(null);
  const [updatedUsers, setUpdatedUsers] = useState({});

  const [totalRows, setTotalRows] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageNo, setPageNo] = useState(0);

  const [searchText, setSearchText] = useState("");
  const [listType, setListType] = useState("All");
  const [searchType, setSearchType] = useState("userId");

  const handleSwitchChange = (e, row) => {
    // onChange={(event) => {
    setUpdatedUsers((prevState) => {
      return { ...prevState, [row?.userId]: { isVerifiedOldValue: row?.userVerified, isVerifiedNewValue: e ? "Yes" : "No" } };
    });
    // }}
  };

  useEffect(() => {
    (async () => {
      await getUserData(listType, "", "", pageNo, rowsPerPage);
    })();
  }, []);

  const handleClick = async (listType) => {
    setListType(listType);
    setPageNo(0);
    setSearchText("");

    await getUserData(listType, searchText, searchType, pageNo, rowsPerPage);
  };

  const handleChangeSearchType = (e) => {
    setSearchType(e.target.value);
  };

  const getUserData = async (listTypeInput, query = "", searchBy = "", pageNo, rowsPerPage) => {
    try {
      const resp = await axiosInstance.get(`/user/listAllUsers?listType=${listTypeInput}&searchBy=${searchBy}&searchText=${query}&page=${pageNo + 1}&rowsPerPage=${rowsPerPage}`);
      const data = await resp.data;
      console.log(data.result.rows);
      setUserListData(data.result.rows);
      setTotalRows(data.result.totalRows);
    } catch (error) {
      console.log("Error get records...");
    }
  };

  const handleProcess = async () => {
    try {
      if (Object.getOwnPropertyNames(updatedUsers).length === 0) {
        createNotification("warning", "No Record Selected..");
        return true;
      }

      const resp = await axiosInstance.post(`/user/update_status`, updatedUsers);
      await getUserData(listType, "", "", pageNo, rowsPerPage);
      createNotification("success", "processing completed");
    } catch (error) {
      createNotification("error", error.message);
      console.log("Error get records...");
    }
  };

  const handleChangePage = async (event, newPage) => {
    setPageNo(newPage);
    await getUserData(listType, searchText, searchText, newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = async (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageNo(0);
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };
  const handleSearch = async () => {
    setPageNo(0);
    setListType("All");
    await getUserData(listType, searchText, searchType, pageNo, rowsPerPage);
  };
  console.log("updated users:::", updatedUsers);
  if (!userListData) {
    return <Box>Loading...</Box>;
  }

  if (userListData) {
    return (
      <>
        <Box className="table-header">
          <Box className="searchWrapper">
            <FormControl fullWidth sx={{ width: 140, height: 40 }}>
              <InputLabel id="demo-simple-select-label">Search </InputLabel>
              <Select labelId="demo-simple-select-label" id="demo-simple-select" sx={{ height: 40 }} label="Search Types" value={searchType} onChange={handleChangeSearchType}>
                <MenuItem selected value="userId">
                  User Id
                </MenuItem>
                <MenuItem value="userEmail">Email</MenuItem>
                <MenuItem value="mobileNumber">Mobile No</MenuItem>
                <MenuItem value="status">User Status</MenuItem>
                <MenuItem value="userIP">User IP</MenuItem>
              </Select>
            </FormControl>

            <Paper component="form" sx={{ p: "2px 4px", display: "flex", alignItems: "center", width: 400, height: "40px" }}>
              <InputBase onChange={handleSearchChange} value={searchText} sx={{ ml: 1, flex: 1 }} placeholder="Search by id, user" />
              <IconButton onClick={handleSearch} type="button" sx={{ p: "4px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
            </Paper>
          </Box>

          <Box className="status-wrapper-main">
            <Box className="status-wrapper">
              <span className="status-title">List User:</span>
              <span className="status-value"> {listType}</span>
            </Box>
            <Box className="statusBox">
              <Button
                size="small"
                variant="contained"
                style={{ textTransform: "none" }}
                onClick={() => {
                  handleClick("All");
                }}
              >
                Show all
              </Button>
              <Button
                size="small"
                variant="contained"
                style={{ textTransform: "none" }}
                onClick={() => {
                  handleClick("Verified");
                }}
              >
                Verified
              </Button>
              <Button
                size="small"
                variant="contained"
                style={{ textTransform: "none" }}
                onClick={() => {
                  handleClick("NotVerified");
                }}
              >
                Not Verified
              </Button>
              <Button size="small" variant="contained" color="success" style={{ textTransform: "none" }} onClick={handleProcess}>
                Process
              </Button>
            </Box>
          </Box>
        </Box>
        <Box>
          <TableContainer component="Box" style={{ width: "100%" }}>
            <Table size="small">
              <TableHead>
                <TableRow className="table-header-wrapper">
                  <TableCell className="header-row">User ID</TableCell>
                  <TableCell className="header-row">Email</TableCell>
                  <TableCell className="header-row">User IP</TableCell>
                  <TableCell className="header-row">Mobile No</TableCell>
                  <TableCell className="header-row">Status</TableCell>
                  <TableCell className="header-row">Blocked</TableCell>
                  <TableCell className="header-row">Redeem Blocked</TableCell>
                  <TableCell className="header-row">Balance</TableCell>
                  <TableCell className="header-row">AID-Chg Count</TableCell>
                  <TableCell className="header-row">Verified</TableCell>
                  <TableCell className="header-row">Change Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="table-body-style" style={{ minHeight: "60v !important" }}>
                {userListData.map((row, index) => {
                  return (
                    <TableRow key={row.userId} style={{ height: "20px" }}>
                      <TableCell variant="caption">{row.userId || "-"}</TableCell>
                      <TableCell variant="caption">{row.userEmail || "-"}</TableCell>
                      <TableCell variant="caption">{row.userIP || "-"}</TableCell>
                      <TableCell variant="caption">{row.mobileNumber || "-"}</TableCell>
                      <TableCell variant="caption">{row.status}</TableCell>
                      <TableCell variant="caption">{row.isBlocked}</TableCell>
                      <TableCell variant="caption">{row.blockOfferwalls}</TableCell>

                      <TableCell variant="caption">{row.accountBalance}</TableCell>
                      <TableCell variant="caption">{row.AIDChangeCount}</TableCell>
                      <TableCell variant="caption">{row.userVerified}</TableCell>
                      <TableCell variant="caption">
                        <IOSSwitch
                          id={"verifyUser_" + row.userId}
                          sx={{ m: 1 }}
                          defaultChecked={row.userVerified === "Yes"}
                          userId={row.userId}
                          setUpdatedUsers={setUpdatedUsers}
                          currentStatus={row.userVerified}
                          handleChange={(e) => handleSwitchChange(e.target.checked, row)}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="Box"
            count={totalRows}
            rowsPerPage={rowsPerPage}
            page={pageNo}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 15]}
          />
        </Box>
      </>
    );
  }
};

export default UserList