import PropTypes from "prop-types";

function PageNotFound() {
  return (
    <div>
      <h1>Page Not Found</h1>
    </div>
  );
}

PageNotFound.propTypes = {};

export default PageNotFound;
