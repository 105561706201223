import * as React from "react";
import { logout, setCurrentUser, setLogin } from "../../login/Slice/loginSlice";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import { useNavigate } from "react-router-dom";
import { updateOpen } from "../slice/navSlice";
import { useSelector, useDispatch } from "react-redux";
import GroupIcon from "@mui/icons-material/Group";
import HomeIcon from "@mui/icons-material/Home";
import LoginIcon from "@mui/icons-material/Login";
import PaidIcon from "@mui/icons-material/Paid";
import SimCardIcon from "@mui/icons-material/SimCard";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import AssistantDirectionIcon from "@mui/icons-material/AssistantDirection";
const drawerWidth = 250;


const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(6)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function SideNave() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const navigate = useNavigate();
  const dopen = useSelector((state) => state.app.dopen);
  const dispatch = useDispatch();

  const handleUpdateOpen = (open) => {
    dispatch(updateOpen(open));
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Box height={40} />

      <Drawer variant="permanent" className="bg-color-custom" open={dopen}>
        <DrawerHeader></DrawerHeader>
        <Divider />
        <List>
          <ListItem
            disablePadding
            sx={{ display: "block" }}
            onClick={() => {
              navigate("/dashboard");
            }}
          >
            <ListItemButton
              sx={{
                minHeight: 32,
                justifyContent: open ? "initial" : "center",
                px: 2,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 24, // Decrease the width of the icon
                  mr: open ? 1 : 0, // Adjust the margin
                }}
              >
                <HomeIcon fontSize="small" /> {/* Adjust the icon size */}
              </ListItemIcon>
              <ListItemText
                primary="Home"
                sx={{
                  opacity: open ? 1 : 0,
                  fontSize: "0.8rem", // Decrease the font size of the text
                }}
              />
            </ListItemButton>
          </ListItem>

          <ListItem
            disablePadding
            sx={{ display: "block" }}
            onClick={() => {
              navigate("/userList");
            }}
          >
            <ListItemButton
              sx={{
                minHeight: 32,
                justifyContent: open ? "initial" : "center",
                px: 2,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 24,
                  mr: open ? 1 : 0, // Adjust the margin
                }}
              >
                <GroupIcon fontSize="small" /> {/* Adjust the icon size */}
              </ListItemIcon>
              <ListItemText
                primary="Users"
                sx={{
                  opacity: open ? 1 : 0,
                  fontSize: "0.8rem", // Decrease the font size of the text
                }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            sx={{ display: "block" }}
            onClick={() => {
              // navigate("/search");
            }}
          >
            <ListItemButton
              sx={{
                minHeight: 32,
                justifyContent: open ? "initial" : "center",
                px: 2,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 24, // Decrease the width of the icon
                  mr: open ? 1 : 0, // Adjust the margin
                }}
              >
                <PersonSearchIcon fontSize="small" />{" "}
                {/* Adjust the icon size */}
              </ListItemIcon>
              <ListItemText
                primary="Search"
                sx={{
                  opacity: open ? 1 : 0,
                  fontSize: "0.7rem", // Decrease the font size of the text
                }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            sx={{ display: "block" }}
            onClick={() => {
              localStorage.removeItem("token");
              dispatch(logout())
              navigate("/");
            }}
          >
            <ListItemButton
              sx={{
                minHeight: 32,
                justifyContent: open ? "initial" : "center",
                px: 2,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 24, // Decrease the width of the icon
                  mr: open ? 1 : 0, // Adjust the margin
                }}
              >
                <LoginIcon fontSize="small" /> {/* Adjust the icon size */}
              </ListItemIcon>
              <ListItemText
                primary="Events"
                sx={{
                  opacity: open ? 1 : 0,
                  fontSize: "0.7rem", // Decrease the font size of the text
                }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            sx={{ display: "block" }}
            onClick={() => {
               navigate("/unprocess_giftcards");
            }}
          >
            <ListItemButton
              sx={{
                minHeight: 32,
                justifyContent: open ? "initial" : "center",
                px: 2,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 24, // Decrease the width of the icon
                  mr: open ? 1 : 0, // Adjust the margin
                }}
              >
              <PaidIcon fontSize="small" /> {/* Adjust the icon size */}
              </ListItemIcon>

              <ListItemText
                primary="Transaction"
                sx={{
                  opacity: open ? 1 : 0,
                  fontSize: "0.7rem", // Decrease the font size of the text
                }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            sx={{ display: "block" }}
            onClick={() => {
              // navigate("/carrier");
            }}
          >
            <ListItemButton
              sx={{
                minHeight: 32,
                justifyContent: open ? "initial" : "center",
                px: 2,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 24, // Decrease the width of the icon
                  mr: open ? 1 : 0, // Adjust the margin
                }}
              >
                <SimCardIcon fontSize="small" /> {/* Adjust the icon size */}
              </ListItemIcon>
              <ListItemText
                primary="Carrier"
                sx={{
                  opacity: open ? 1 : 0,
                  fontSize: "0.7rem", // Decrease the font size of the text
                }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            sx={{ display: "block" }}
            onClick={() => {
              // navigate("/gateway");
            }}
          >
            <ListItemButton
              sx={{
                minHeight: 32,
                justifyContent: open ? "initial" : "center",
                px: 2,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 24, // Decrease the width of the icon
                  mr: open ? 1 : 0, // Adjust the margin
                }}
              >
                <AssistantDirectionIcon fontSize="small" />{" "}
                {/* Adjust the icon size */}
              </ListItemIcon>
              <ListItemText
                primary="GateWay logs"
                sx={{
                  opacity: open ? 1 : 0,
                  fontSize: "0.7rem", // Decrease the font size of the text
                }}
              />
            </ListItemButton>
          </ListItem>
        </List>
        <Divider />
      </Drawer>
    </Box>
  );
}
